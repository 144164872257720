.signUp {
  text-decoration: none;
  font-size: 0.8rem;
  color: #bdc4c9;
}

.login {
  color: #2a7de1;
  text-decoration: none;
}
